// Libraries
import React from 'react';

// Supermove
import {IconSource, ScrollView, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors} from '@supermove/styles';

import Drawer, {DrawerProps, DrawerBodyProps} from '@shared/design/components/Drawer';
import {GetRefType} from '@shared/utilities/typescript';

// App

const LoadingIndicatorContainer = Styled.View`
  flex: 1;
  justify-content: center;
`;

const LoadingIndicator = Styled.Loading`
`;

export interface LoadingComponentProps {
  isOpen: boolean;
  handleClose: () => void;
  headerText: string;
  width?: number;
  isVisibleFooter?: boolean;
  primaryActionText?: string;
  secondaryActionText?: string;
  shouldCloseOnClickOutside?: boolean;
  isClosable?: boolean;
  HeaderRightComponent?: React.FC;
  children?: React.ReactNode;
}

const LoadingComponent = ({
  isOpen = false,
  handleClose = () => {},
  headerText = '',
  width = Drawer.WIDTH.DEFAULT,
  isVisibleFooter = true,
  primaryActionText = 'Submit',
  secondaryActionText = 'Cancel',
  shouldCloseOnClickOutside = true,
  isClosable = false,
  HeaderRightComponent = () => null,
  children,
}: LoadingComponentProps) => {
  const responsive = useResponsive();

  return (
    <Drawer
      isOpen={isOpen}
      handleClose={handleClose}
      width={width}
      shouldCloseOnClickOutside={responsive.desktop && shouldCloseOnClickOutside}
      isAnimated={isOpen}
    >
      <Drawer.Header
        headerText={headerText}
        handleClose={handleClose}
        isClosable={isClosable}
        isResponsive
      >
        <HeaderRightComponent />
      </Drawer.Header>
      {children ? (
        <Drawer.Body>{children}</Drawer.Body>
      ) : (
        <LoadingIndicatorContainer>
          <LoadingIndicator size={'large'} color={colors.gray.secondary} />
        </LoadingIndicatorContainer>
      )}
      {isVisibleFooter &&
        (responsive.desktop ? (
          <Drawer.Footer
            isDisabled
            primaryAction={() => {}}
            secondaryAction={handleClose}
            primaryActionText={primaryActionText}
            secondaryActionText={secondaryActionText}
          />
        ) : (
          <Drawer.FooterMobile
            isDisabled
            primaryAction={() => {}}
            primaryActionText={primaryActionText}
          />
        ))}
    </Drawer>
  );
};
export interface DrawerWithActionProps extends DrawerProps, DrawerBodyProps {
  headerText: string;
  description?: string | React.ReactNode;
  width?: number;
  isVisibleFooter?: boolean;
  isDisabled?: boolean;
  isSubmitting?: boolean;
  handleClose?: () => void;
  handleSubmit?: () => void;
  primaryActionText?: string;
  primaryActionIcon?: IconSource;
  secondaryActionText?: string;
  shouldCloseOnClickOutside?: boolean;
  isClosable?: boolean;
  HeaderRightComponent?: React.FC;
  bodyStyle?: object;
  bodyFooterComponent?: React.ReactNode;
  children: React.ReactNode;
  isUsingLoadingComponent?: boolean;
  scrollViewRef?: GetRefType<typeof ScrollView>;
  secondaryAction?: () => void;
  // Side panel props
  popoutPanelTitle?: string;
  popoutPanelDescription?: string;
  handleClosePopoutPanel?: () => void;
}

const DrawerWithAction = ({
  isOpen = false,
  handleClose = () => {},
  headerText = '',
  description = '',
  width = Drawer.WIDTH.DEFAULT,

  // isVisibleFooter should generally not be used. It was
  // added to cover an edge case where a drawer has a toggle
  // that can enable footer actions.
  isVisibleFooter = true,

  bodyFooterComponent = null,
  popoutPanel,
  isDisabled = false,
  isSubmitting = false,
  handleSubmit = () => {},
  children,
  primaryActionText = 'Submit',
  secondaryActionText = 'Cancel',
  shouldCloseOnClickOutside = true,
  isClosable = false,
  isUsingLoadingComponent = false,
  primaryActionIcon,
  scrollViewRef,
  // HeaderRightComponent is only applied to the drawer
  HeaderRightComponent = () => null,
  bodyStyle = {},
}: DrawerWithActionProps) => {
  const responsive = useResponsive();

  return (
    <Drawer
      isOpen={isOpen}
      handleClose={handleClose}
      width={width}
      shouldCloseOnClickOutside={responsive.desktop && shouldCloseOnClickOutside}
      isAnimated={!isUsingLoadingComponent || !isOpen}
      popoutPanel={popoutPanel}
    >
      <Drawer.Header
        headerText={headerText}
        description={description}
        handleClose={handleClose}
        isClosable={isClosable}
        isResponsive
      >
        <HeaderRightComponent />
      </Drawer.Header>
      <Drawer.Body scrollViewRef={scrollViewRef} bodyScrollStyle={bodyStyle}>
        {children}
      </Drawer.Body>
      {!!bodyFooterComponent && bodyFooterComponent}
      {isVisibleFooter &&
        (responsive.desktop ? (
          <Drawer.Footer
            isDisabled={isDisabled}
            isSubmitting={isSubmitting}
            primaryAction={handleSubmit}
            secondaryAction={handleClose}
            primaryActionText={primaryActionText}
            secondaryActionText={secondaryActionText}
            primaryActionIcon={primaryActionIcon}
          />
        ) : (
          <Drawer.FooterMobile
            isDisabled={isDisabled}
            isSubmitting={isSubmitting}
            primaryAction={handleSubmit}
            primaryActionText={primaryActionText}
          />
        ))}
    </Drawer>
  );
};

DrawerWithAction.Text = Drawer.Text;
DrawerWithAction.WIDTH = Drawer.WIDTH;
DrawerWithAction.Body = Drawer.Body;
DrawerWithAction.PopoutPanel = Drawer.PopoutPanel;
DrawerWithAction.LoadingComponent = LoadingComponent;

export default DrawerWithAction;
