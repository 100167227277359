// Libraries
import React from 'react';

// Supermove

// App
import Drawer, {DrawerProps, DrawerBodyProps} from '@shared/design/components/Drawer';

export interface DrawerWithCloseProps extends DrawerProps, DrawerBodyProps {
  isOpen: boolean;
  handleClose: () => void;
  headerText: string;
  description?: string;
  width?: number;
  children: React.ReactNode;
  bodyProps?: object;
  isAnimated?: boolean;
}

const DrawerWithClose = ({
  isOpen = false,
  handleClose = () => {},
  headerText = '',
  description = '',
  width = Drawer.WIDTH.DEFAULT,
  children,
  bodyProps = {},
  popoutPanel,
  isAnimated,
}: DrawerWithCloseProps) => {
  return (
    <Drawer
      isOpen={isOpen}
      handleClose={handleClose}
      width={width}
      popoutPanel={popoutPanel}
      isAnimated={isAnimated}
    >
      <Drawer.Header
        headerText={headerText}
        description={description}
        handleClose={handleClose}
        isClosable
        isResponsive
      />
      <Drawer.Body {...bodyProps}>{children}</Drawer.Body>
    </Drawer>
  );
};

DrawerWithClose.Text = Drawer.Text;
DrawerWithClose.WIDTH = Drawer.WIDTH;
DrawerWithClose.PopoutPanel = Drawer.PopoutPanel;

export default DrawerWithClose;
