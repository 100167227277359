// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {
  AccountGrantModel,
  EmailModel,
  EmailRecipientModel,
  EmailTemplate,
  EmailTemplateModel,
  ProjectModel,
  Proposal,
  UserModel,
} from '@supermove/models';
import {HTML, withFragment} from '@supermove/utils';

// App
import EmailTemplateBodyKind from '@shared/modules/Email/enums/EmailTemplateBodyKind';
import EmailRecipientForm from '@shared/modules/Email/forms/EmailRecipientForm';
import {addBodyToForwardedMessage, addForwardToBody} from '@shared/modules/Email/utils/fowarding';
import {parseJsonRecipients} from '@shared/modules/Email/utils/parseJsonRecipients';
import UserRole, {RoleType} from '@shared/modules/User/enums/UserRole';
import UserStatus from '@shared/modules/User/enums/UserStatus';

interface EmailRecipientFormType {
  email: string;
}

export interface EmailRecipientOptionType {
  value?: string;
  label?: string;
  email?: string;
  disabled?: boolean;
}

export interface EmailFormToFormType {
  organizationId: string;
  customerId: string;
  senderId: string;
  attachmentIds: string[];
  emailTemplateId: string;
  threadId?: string;
  kind: string;
  toEmailRecipientForms: EmailRecipientFormType[];
  ccEmailRecipientForms: EmailRecipientFormType[];
  bccEmailRecipientForms: EmailRecipientFormType[];
  subject: string;
  body: string;
  documentTemplateKinds: string[];
  accountGrantId?: string;

  // Private
  isShowingCc: boolean;
  isShowingBcc: boolean;
  toEmailRecipientValues: string[];
  ccEmailRecipientValues: string[];
  bccEmailRecipientValues: string[];
  searchInputValue: string;
  emailRecipientOptions: EmailRecipientOptionType[];
  bodyKind?: string;
  htmlToKeepWithForwarding?: string;
  accountGrantOptions: EmailRecipientOptionType[];
}

// This might be different later, for now it's the same
export type EmailFormType = EmailFormToFormType;

const getRoleEmailRecipientForms = withFragment<
  {role?: string; project: ProjectModel},
  EmailRecipientFormType[] | null,
  unknown
>(
  ({role, project}) => {
    const projectEmailRecipient = _.find(
      project.projectEmailRecipients,
      (recipient) => _.toLower(role) === _.toLower(recipient.role),
    );
    const email = projectEmailRecipient?.email;
    return email
      ? email.split(',').map((emailAddress) => EmailRecipientForm.new({email: emailAddress.trim()}))
      : null;
  },
  gql`
    fragment EmailForm_getRoleEmailRecipientForms on Project {
      id
      projectEmailRecipients {
        role
        email
      }
    }
  `,
);

const getEmailRecipientForms = withFragment<
  {emailTemplateRecipients: EmailRecipientModel[]; project: ProjectModel},
  EmailRecipientFormType[],
  unknown
>(
  ({emailTemplateRecipients, project}) => {
    if (!emailTemplateRecipients) {
      return [];
    }
    const emailRecipientForms = emailTemplateRecipients.flatMap((emailTemplateRecipient) => {
      const {email, user, role} = emailTemplateRecipient;
      if (role) {
        return getRoleEmailRecipientForms({role, project});
      }
      if (user && user.email) {
        // Make sure an employee is active
        if (
          UserRole.OFFICE_ROLES_PLUS_SUPER.includes(user.role) &&
          user.status !== UserStatus.ACTIVE
        ) {
          return [];
        }
        return EmailRecipientForm.new({email: user.email});
      }
      if (email) {
        return EmailRecipientForm.new({email});
      }
      return null;
    });

    return emailRecipientForms.filter((form) => !!form) as EmailRecipientFormType[];
  },
  gql`
    ${getRoleEmailRecipientForms.fragment}

    fragment EmailForm_getEmailRecipientForms_Project on Project {
      id
      ...EmailForm_getRoleEmailRecipientForms
    }

    fragment EmailForm_getEmailRecipientForms_EmailTemplateRecipient on EmailTemplateRecipient {
      email
      role
      user {
        id
        email
        status
      }
    }
  `,
);

const getEmailRecipientValues = withFragment<
  {emailTemplateRecipients: EmailRecipientModel[]},
  string[],
  unknown
>(
  ({emailTemplateRecipients}) => {
    if (!emailTemplateRecipients) {
      return [];
    }
    const emailRecipientValues = emailTemplateRecipients.map((emailTemplateRecipient) => {
      const {email, user, role} = emailTemplateRecipient;
      const userEmail = user ? user.email : null;
      return role || email || userEmail;
    });
    return emailRecipientValues.filter((value) => !!value) as string[];
  },
  gql`
    fragment EmailForm_getEmailRecipientValues on EmailTemplateRecipient {
      email
      role
      user {
        id
        email
      }
    }
  `,
);

const RECIPIENT_DISPLAY_VALUES = {
  [EmailTemplate.RECIPIENT_VALUES.PROJECT_COORDINATOR]: 'Coordinator',
  [EmailTemplate.RECIPIENT_VALUES.PROJECT_CUSTOMER]: 'Customer',
  [EmailTemplate.RECIPIENT_VALUES.PROJECT_SALESPERSON]: 'Salesperson',
  [EmailTemplate.RECIPIENT_VALUES.PROJECT_BRANCH]: 'Branch Notification Emails',
  [EmailTemplate.RECIPIENT_VALUES.COMPANY_NOTIFICATION_EMAILS]: 'Company Notification Emails',
  [EmailTemplate.RECIPIENT_VALUES.ADDITIONAL_SALESPERSON_EMAILS]:
    'Project Additional Salesperson Emails',
};

const getDisplayRole = (role: RoleType) => {
  return _.get(RECIPIENT_DISPLAY_VALUES, role);
};

// TODO(dan) Waiting on ProjectEmailTemplate to be setup. ProjectEmailTemplate should
// have projectEmailTemplateRecipients which should have email template recipients plus
// projectEmailRecipients.
const getEmailRecipientOptions = withFragment<
  {project: ProjectModel; emailTemplate?: EmailTemplateModel},
  EmailRecipientOptionType[],
  unknown
>(
  ({project, emailTemplate}) => {
    const emailRecipientOptions: EmailRecipientOptionType[] = project.projectEmailRecipients.map(
      ({role, email, label}) => {
        return {
          value: role || email,
          label: `${role ? `${getDisplayRole(role)}: ` : ''}${label} <${email}>`,
          email,
        };
      },
    );
    if (!emailTemplate) {
      return emailRecipientOptions;
    }
    _.get(emailTemplate, 'toEmailTemplateRecipients', []).forEach(
      (recipient: EmailRecipientOptionType) => {
        if (recipient.email) {
          emailRecipientOptions.push({
            value: recipient.email,
            label: recipient.email as string,
          });
        }
      },
    );
    _.get(emailTemplate, 'ccEmailTemplateRecipients', []).forEach(
      (recipient: EmailRecipientOptionType) => {
        if (recipient.email) {
          emailRecipientOptions.push({
            value: recipient.email,
            label: recipient.email,
          });
        }
      },
    );
    _.get(emailTemplate, 'bccEmailTemplateRecipients', []).forEach(
      (recipient: EmailRecipientOptionType) => {
        if (recipient.email) {
          emailRecipientOptions.push({
            value: recipient.email,
            label: recipient.email,
          });
        }
      },
    );
    return emailRecipientOptions;
  },
  gql`
    fragment EmailForm_getEmailRecipientOptions on Project {
      id
      projectEmailRecipients {
        role
        label
        email
      }
    }
  `,
);

// This takes in an email, and either finds the matching option in the recipient option or adds it to the list
const getEmailValueAndRecipientOptionsFromNewEmails = (
  emailRecipientForm: EmailRecipientFormType[],
  emailRecipientOptions: EmailRecipientOptionType[],
) => {
  const newRecipientOptions = [...emailRecipientOptions];
  const emailValues = emailRecipientForm
    .map((form) => {
      if (!form.email) {
        return undefined;
      }
      const {email} = form;
      const existingOption = _.find(newRecipientOptions, (option) => option.email === email);
      if (existingOption) {
        return existingOption.value || existingOption.email || email;
      } else {
        newRecipientOptions.push({value: email, label: email});
        return email;
      }
    })
    .filter(Boolean) as string[];
  return {emailValues, newRecipientOptions};
};

const sortAccountGrants = (a: AccountGrantModel, b: AccountGrantModel) => {
  const sortByKind = (a: AccountGrantModel, b: AccountGrantModel) => {
    if (a.kind === 'USER') {
      return -1;
    }
    if (b.kind === 'USER') {
      return 1;
    }
    return 0;
  };
  // Kind should be enough, but for future-proofing sort by recent as a backup
  const sortByRecent = (a: AccountGrantModel, b: AccountGrantModel) => {
    return a.updatedAt.localeCompare(b.updatedAt);
  };

  return sortByKind(a, b) || sortByRecent(a, b);
};

const getFromAccountGrantOptions = withFragment<
  {viewer?: UserModel},
  {label: string; value?: string}[],
  unknown
>(
  ({viewer}) => {
    if (!viewer) {
      return [];
    }
    if (!viewer.viewingOrganization?.features.isEnabledSelectFromAccount) {
      return [];
    }
    if (!viewer.availableAccountGrants) {
      return [];
    }
    return viewer.availableAccountGrants.sort(sortAccountGrants).map((accountGrant) => ({
      label: accountGrant.displayName,
      value: accountGrant.id,
    }));
  },
  gql`
    fragment EmailForm_getFromAccountGrantOptions on User {
      id
      viewingOrganization {
        id
        features {
          isEnabledSelectFromAccount: isEnabled(feature: "SELECT_FROM_ACCOUNT")
        }
      }
      availableAccountGrants {
        id
        identifier
        updatedAt
        displayName
        kind
      }
    }
  `,
);

const newFromProject = withFragment<
  {project: ProjectModel; viewer: UserModel; threadId?: string; subject?: string},
  EmailFormToFormType,
  unknown
>(
  ({project, viewer, threadId, subject = ''}) => {
    const fromAccountGrantOptions = getFromAccountGrantOptions({viewer});
    return {
      organizationId: project.organizationId,
      customerId: project.customerId,
      senderId: viewer.id,
      emailTemplateId: '',
      threadId,
      kind: 'CUSTOM',
      toEmailRecipientForms: [],
      ccEmailRecipientForms: [],
      bccEmailRecipientForms: [],
      subject,
      body: '',
      attachmentIds: [],
      documentTemplateKinds: [],
      accountGrantId: fromAccountGrantOptions[0]?.value,

      // Private
      isShowingCc: false,
      isShowingBcc: false,
      toEmailRecipientValues: [],
      ccEmailRecipientValues: [],
      bccEmailRecipientValues: [],
      searchInputValue: '',
      emailRecipientOptions: getEmailRecipientOptions({project}),
      accountGrantOptions: fromAccountGrantOptions,
    };
  },
  gql`
    ${getEmailRecipientOptions.fragment}
    ${getFromAccountGrantOptions.fragment}

    fragment EmailForm_newFromProject on Project {
      id
      organizationId
      customerId
      ...EmailForm_getEmailRecipientOptions
    }

    fragment EmailForm_newFromProject_User on User {
      id
      ...EmailForm_getFromAccountGrantOptions
    }
  `,
);

interface NewForReplyParams {
  project: ProjectModel;
  viewer: UserModel;
  email: EmailModel;
}

const newForReply = withFragment<NewForReplyParams, EmailFormToFormType, unknown>(
  ({project, viewer, email}) => {
    const originalToEmailRecipients = parseJsonRecipients(email.toEmailRecipientsJson);

    // If it's from the org, keep the original recipients. If it's to the org, then the from email is who it should be to.
    const toEmailRecipientForms = email.isFromOrganization
      ? originalToEmailRecipients.slice(0, 1).map((email) => EmailRecipientForm.new({email}))
      : [EmailRecipientForm.new({email: email.fromEmail})];
    const recipientOptions = getEmailRecipientOptions({project});
    const {emailValues: toEmailValues, newRecipientOptions} =
      getEmailValueAndRecipientOptionsFromNewEmails(toEmailRecipientForms, recipientOptions);
    const fromAccountGrantOptions = getFromAccountGrantOptions({viewer});

    return {
      organizationId: project.organizationId,
      customerId: project.customerId,
      senderId: viewer.id,
      emailTemplateId: '',
      threadId: email.threadId,
      kind: 'CUSTOM',
      toEmailRecipientForms,
      ccEmailRecipientForms: [],
      bccEmailRecipientForms: [],
      subject: email.subject,
      body: '',
      attachmentIds: [],
      documentTemplateKinds: [],
      accountGrantId: fromAccountGrantOptions[0]?.value,

      // Private
      isShowingCc: false,
      isShowingBcc: false,
      toEmailRecipientValues: toEmailValues,
      ccEmailRecipientValues: [],
      bccEmailRecipientValues: [],
      searchInputValue: '',
      emailRecipientOptions: newRecipientOptions,
      accountGrantOptions: fromAccountGrantOptions,
    };
  },
  gql`
    ${getEmailRecipientOptions.fragment}

    fragment EmailForm_newForReply_Project on Project {
      id
      organizationId
      customerId
      ...EmailForm_getEmailRecipientOptions
    }

    fragment EmailForm_newForReply_Email on Email {
      id
      fromEmail
      toEmailRecipientsJson
      subject
      threadId
      isFromOrganization
    }

    fragment EmailForm_newForReply_User on User {
      id
      ...EmailForm_getFromAccountGrantOptions
    }
  `,
);

const newForReplyAll = withFragment<NewForReplyParams, EmailFormToFormType, unknown>(
  ({project, viewer, email}) => {
    const originalToEmailRecipients = parseJsonRecipients(email.toEmailRecipientsJson);
    const originalCcEmailRecipients = parseJsonRecipients(email.ccEmailRecipientsJson);
    const originalBccEmailRecipients = parseJsonRecipients(email.bccEmailRecipientsJson);

    const replyAllForm = newForReply({
      project,
      viewer,
      email,
    });

    replyAllForm.toEmailRecipientForms = originalToEmailRecipients.map((email) =>
      EmailRecipientForm.new({email}),
    );
    replyAllForm.ccEmailRecipientForms = originalCcEmailRecipients.map((email) =>
      EmailRecipientForm.new({email}),
    );
    replyAllForm.bccEmailRecipientForms = originalBccEmailRecipients.map((email) =>
      EmailRecipientForm.new({email}),
    );
    replyAllForm.isShowingCc = originalCcEmailRecipients.length > 0;
    replyAllForm.isShowingBcc = originalBccEmailRecipients.length > 0;

    const {emailValues: ccEmailValues, newRecipientOptions: newCcRecipientOptions} =
      getEmailValueAndRecipientOptionsFromNewEmails(
        replyAllForm.ccEmailRecipientForms,
        replyAllForm.emailRecipientOptions,
      );
    const {emailValues: bccEmailValues, newRecipientOptions: newBccRecipientOptions} =
      getEmailValueAndRecipientOptionsFromNewEmails(
        replyAllForm.bccEmailRecipientForms,
        newCcRecipientOptions,
      );

    replyAllForm.ccEmailRecipientValues = ccEmailValues;
    replyAllForm.bccEmailRecipientValues = bccEmailValues;
    replyAllForm.emailRecipientOptions = newBccRecipientOptions;

    return replyAllForm;
  },
  gql`
    ${newForReply.fragment}

    fragment EmailForm_newForReplyAll_Project on Project {
      id
      ...EmailForm_newForReply_Project
    }

    fragment EmailForm_newForReplyAll_Email on Email {
      id
      ...EmailForm_newForReply_Email
    }

    fragment EmailForm_newForReplyAll_User on User {
      id
      ...EmailForm_newForReply_User
    }
  `,
);

const newForForward = withFragment<NewForReplyParams, EmailFormToFormType, unknown>(
  ({project, viewer, email}) => {
    const forwardForm = newForReply({
      project,
      viewer,
      email,
    });

    forwardForm.subject = `Fwd: ${email.subject}`;
    forwardForm.body = addForwardToBody(email);
    forwardForm.toEmailRecipientForms = [];
    forwardForm.htmlToKeepWithForwarding = addForwardToBody(email);

    return forwardForm;
  },
  gql`
    ${newForReply.fragment}

    fragment EmailForm_newForForward_Project on Project {
      id
      ...EmailForm_newForReply_Project
    }

    fragment EmailForm_newForForward_Email on Email {
      id
      subject
      ...EmailForm_newForReply_Email
    }

    fragment EmailForm_newForForward_User on User {
      id
      ...EmailForm_newForReply_User
    }
  `,
);

const newFromProjectWithEmailTemplate = withFragment<
  {
    viewer: UserModel;
    project: ProjectModel;
    emailTemplate: EmailTemplateModel;
    threadId?: string;
    subject?: string;
  },
  EmailFormToFormType,
  unknown
>(
  ({viewer, project, emailTemplate, threadId, subject}) => {
    const toEmailRecipientValues = getEmailRecipientValues({
      emailTemplateRecipients: emailTemplate.toEmailTemplateRecipients,
    });
    const ccEmailRecipientValues = getEmailRecipientValues({
      emailTemplateRecipients: emailTemplate.ccEmailTemplateRecipients,
    });
    const bccEmailRecipientValues = getEmailRecipientValues({
      emailTemplateRecipients: emailTemplate.bccEmailTemplateRecipients,
    });
    const fromAccountGrantOptions = getFromAccountGrantOptions({viewer});

    return {
      organizationId: project.organizationId,
      customerId: project.customerId,
      senderId: viewer.id,
      // TODO(dan) Once migrated over to ProjectEmailTemplate, we should be able
      // to remove lodash here and not have to account for the case where no value
      // is returned for emailTemplateAttachments.
      attachmentIds: _.get(emailTemplate, 'emailTemplateAttachments', []).map(
        (emailTemplateAttachment) => emailTemplateAttachment.attachment.id,
      ),
      emailTemplateId: emailTemplate.id,
      threadId,
      kind: emailTemplate.kind,
      toEmailRecipientForms: getEmailRecipientForms({
        emailTemplateRecipients: emailTemplate.toEmailTemplateRecipients,
        project,
      }),
      ccEmailRecipientForms: getEmailRecipientForms({
        emailTemplateRecipients: emailTemplate.ccEmailTemplateRecipients,
        project,
      }),
      bccEmailRecipientForms: getEmailRecipientForms({
        emailTemplateRecipients: emailTemplate.bccEmailTemplateRecipients,
        project,
      }),
      subject: subject ?? emailTemplate.subject,
      body: emailTemplate.body,
      documentTemplateKinds: [],
      accountGrantId: fromAccountGrantOptions[0]?.value,

      // Private
      isShowingCc: ccEmailRecipientValues.length > 0,
      isShowingBcc: bccEmailRecipientValues.length > 0,
      toEmailRecipientValues,
      ccEmailRecipientValues,
      bccEmailRecipientValues,
      searchInputValue: '',
      emailRecipientOptions: getEmailRecipientOptions({project, emailTemplate}),
      bodyKind: emailTemplate.bodyKind,
      accountGrantOptions: fromAccountGrantOptions,
    };
  },
  gql`
    ${EmailTemplate.getCustomRecipientDropdownOptions.fragment}
    ${Proposal.getProjectTypeConfirmationStepsShownByDefault.fragment}
    ${getEmailRecipientForms.fragment}
    ${getEmailRecipientValues.fragment}
    ${getEmailRecipientOptions.fragment}
    ${getFromAccountGrantOptions.fragment}

    fragment EmailForm_newFromProjectWithEmailTemplate_Project on Project {
      id
      organizationId
      customerId
      organization {
        id
      }
      projectType {
        id
        ...Proposal_getProjectTypeConfirmationStepsShownByDefault
      }
      ...EmailForm_getEmailRecipientForms_Project
      ...EmailForm_getEmailRecipientOptions
    }

    fragment EmailForm_newFromProjectWithEmailTemplate_EmailTemplate on EmailTemplate {
      id
      kind
      bodyKind
      subject
      body
      toEmailTemplateRecipients {
        ...EmailForm_getEmailRecipientForms_EmailTemplateRecipient
        ...EmailForm_getEmailRecipientValues
      }
      ccEmailTemplateRecipients {
        ...EmailForm_getEmailRecipientForms_EmailTemplateRecipient
        ...EmailForm_getEmailRecipientValues
      }
      bccEmailTemplateRecipients {
        ...EmailForm_getEmailRecipientForms_EmailTemplateRecipient
        ...EmailForm_getEmailRecipientValues
      }
      emailTemplateAttachments {
        id
        attachment {
          id
        }
      }
      ...EmailTemplate_getCustomRecipientDropdownOptions
    }

    fragment EmailForm_newFromProjectWithEmailTemplate_User on User {
      id
      ...EmailForm_getFromAccountGrantOptions
    }
  `,
);

const alterFormWithTemplate = withFragment<
  {
    emailTemplate: EmailTemplateModel;
    viewer: UserModel;
    form: EmailFormType;
    project: ProjectModel;
    body: string;
  },
  EmailFormToFormType,
  unknown
>(
  ({emailTemplate, viewer, form, project, body}) => {
    const emailRecipientOptions = getEmailRecipientOptions({project, emailTemplate});
    const {emailValues: toEmailRecipientValues, newRecipientOptions: newToRecipientOptions} =
      getEmailValueAndRecipientOptionsFromNewEmails(
        form.toEmailRecipientForms,
        emailRecipientOptions,
      );
    const {emailValues: ccEmailRecipientValues, newRecipientOptions: newCcRecipientOptions} =
      getEmailValueAndRecipientOptionsFromNewEmails(
        form.ccEmailRecipientForms,
        newToRecipientOptions,
      );
    const {emailValues: bccEmailRecipientValues, newRecipientOptions: newBccRecipientOptions} =
      getEmailValueAndRecipientOptionsFromNewEmails(
        form.bccEmailRecipientForms,
        newCcRecipientOptions,
      );
    const fromAccountGrantOptions = getFromAccountGrantOptions({viewer});

    return {
      organizationId: form.organizationId,
      customerId: form.customerId,
      senderId: form.senderId,
      // TODO(dan) Once migrated over to ProjectEmailTemplate, we should be able
      // to remove lodash here and not have to account for the case where no value
      // is returned for emailTemplateAttachments.
      attachmentIds: _.get(emailTemplate, 'emailTemplateAttachments', []).map(
        (emailTemplateAttachment) => emailTemplateAttachment.attachment.id,
      ),
      emailTemplateId: emailTemplate.id,
      threadId: form.threadId,
      kind: emailTemplate.kind,
      toEmailRecipientForms: form.toEmailRecipientForms,
      ccEmailRecipientForms: form.ccEmailRecipientForms,
      bccEmailRecipientForms: form.bccEmailRecipientForms,
      subject: form.subject,
      body: form.htmlToKeepWithForwarding
        ? addBodyToForwardedMessage(body, form.htmlToKeepWithForwarding)
        : body,
      documentTemplateKinds: [],
      accountGrantId: fromAccountGrantOptions[0]?.value,

      // Private
      isShowingCc: form.ccEmailRecipientForms.length > 0,
      isShowingBcc: form.bccEmailRecipientForms.length > 0,
      toEmailRecipientValues,
      ccEmailRecipientValues,
      bccEmailRecipientValues,
      searchInputValue: '',
      emailRecipientOptions: newBccRecipientOptions,
      bodyKind: emailTemplate.bodyKind,
      htmlToKeepWithForwarding: form.htmlToKeepWithForwarding,
      accountGrantOptions: fromAccountGrantOptions,
    };
  },
  gql`
    ${EmailTemplate.getCustomRecipientDropdownOptions.fragment}
    ${Proposal.getProjectTypeConfirmationStepsShownByDefault.fragment}
    ${getEmailRecipientForms.fragment}
    ${getEmailRecipientValues.fragment}
    ${getEmailRecipientOptions.fragment}
    ${getFromAccountGrantOptions.fragment}
    fragment EmailForm_newFromProjectWithEmailTemplate_Project on Project {
      id
      organizationId
      customerId
      organization {
        id
      }
      projectType {
        id
        ...Proposal_getProjectTypeConfirmationStepsShownByDefault
      }
      ...EmailForm_getEmailRecipientForms_Project
      ...EmailForm_getEmailRecipientOptions
    }

    fragment EmailForm_newFromProjectWithEmailTemplate_EmailTemplate on EmailTemplate {
      id
      kind
      bodyKind
      body
      emailTemplateAttachments {
        id
        attachment {
          id
        }
      }
      ...EmailTemplate_getCustomRecipientDropdownOptions
    }

    fragment EmailForm_newFromProjectWithEmailTemplate_User on User {
      id
      ...EmailForm_getFromAccountGrantOptions
    }
  `,
);

const toForm = ({
  organizationId,
  customerId,
  senderId,
  attachmentIds,
  emailTemplateId,
  threadId,
  kind,
  toEmailRecipientForms,
  ccEmailRecipientForms,
  bccEmailRecipientForms,
  subject,
  body,
  documentTemplateKinds,
  accountGrantId,
  isShowingCc,
  isShowingBcc,
  toEmailRecipientValues,
  ccEmailRecipientValues,
  bccEmailRecipientValues,
  searchInputValue,
  emailRecipientOptions,
  htmlToKeepWithForwarding,
  bodyKind,
  accountGrantOptions,
}: EmailFormType) => ({
  organizationId,
  customerId,
  senderId,
  attachmentIds,
  emailTemplateId,
  threadId,
  kind,
  toEmailRecipientForms: toEmailRecipientForms.map((form) => EmailRecipientForm.toForm(form)),
  ccEmailRecipientForms: ccEmailRecipientForms.map((form) => EmailRecipientForm.toForm(form)),
  bccEmailRecipientForms: bccEmailRecipientForms.map((form) => EmailRecipientForm.toForm(form)),
  subject,
  body,
  documentTemplateKinds,
  accountGrantId,

  // Private
  isShowingCc,
  isShowingBcc,
  toEmailRecipientValues,
  ccEmailRecipientValues,
  bccEmailRecipientValues,
  searchInputValue,
  emailRecipientOptions,
  htmlToKeepWithForwarding,
  bodyKind,
  accountGrantOptions,
});

const toMutation = ({
  organizationId,
  customerId,
  senderId,
  threadId,
  attachmentIds,
  kind,
  toEmailRecipientForms,
  ccEmailRecipientForms,
  bccEmailRecipientForms,
  subject,
  body,
  documentTemplateKinds,
  bodyKind,
  accountGrantId,
}: EmailFormType) => ({
  organizationId,
  customerId,
  senderId,
  threadId,
  attachmentIds,
  kind,
  toEmailRecipientForms: toEmailRecipientForms.map((form) => EmailRecipientForm.toMutation(form)),
  ccEmailRecipientForms: ccEmailRecipientForms.map((form) => EmailRecipientForm.toMutation(form)),
  bccEmailRecipientForms: bccEmailRecipientForms.map((form) => EmailRecipientForm.toMutation(form)),
  subject,
  body: bodyKind === EmailTemplateBodyKind.HTML ? body : HTML.fixRichTextEditor(body),
  documentTemplateKinds,
  accountGrantId,
});

const EmailForm = {
  newFromProject,
  newFromProjectWithEmailTemplate,
  newForReply,
  newForReplyAll,
  newForForward,
  toForm,
  toMutation,
  alterFormWithTemplate,
};

export default EmailForm;
